import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useNavigate } from "react-router-dom";
import { setCallData } from "../redux/reducers/callReducer";
import { useTranslation } from "react-i18next";
import {
  addStay,
  addStayScreenshot,
  editStay,
  getAcsAuth,
  getAcsToken,
} from "../api";
import {
  off,
  onChildChanged,
  onValue,
  push,
  ref,
  update,
} from "firebase/database";
import { db } from "../config/firebase";
import { createCanvas } from "canvas";
import { Dialog, Transition } from "@headlessui/react";
import Terms from "../components/Terms";
import QRCode from "react-qr-code";
import StationVideo from "../components/StationVideo";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import { initializeIcons, registerIcons } from "@fluentui/react";
import {
  CallAgentProvider,
  CallClientProvider,
  CallProvider,
  DEFAULT_COMPONENT_ICONS,
  FluentThemeProvider,
  createStatefulCallClient,
} from "@azure/communication-react";
import { LocalVideoStream } from "@azure/communication-calling";

export default function Call() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const stationData =
    useAppSelector((state) => state.data.stationData) ??
    JSON.parse(localStorage.getItem("stationData") ?? "{}");
  const currentCall =
    useAppSelector((state) => state.call.currentCall) ??
    JSON.parse(localStorage.getItem("currentCall") ?? "{}");
  const onlinePeople = localStorage.getItem("onlinePeople")
    ? JSON.parse(localStorage.getItem("onlinePeople") ?? "{}")
    : useAppSelector((state) => state.data.onlinePeople);

  const currentDepartmentData =
    stationData &&
    currentCall &&
    stationData.departments &&
    currentCall.selectedDepartments
      ? stationData.departments.find(
          (department: any) =>
            department.id === parseInt(currentCall.selectedDepartments)
        )
      : null;

  const [showTermAndConditions, setShowTermAndConditions] =
    useState<boolean>(false);
  const [onHold, setOnHold] = useState<boolean>(false);
  const [idShareAccepted, setIdShareAccepted] = useState<boolean>(false);
  const [showAlertIdSharing, setShowAlertIdSharing] = useState<boolean>(false);
  const [showIdOverlay, setShowIdOverlay] = useState<boolean>(false);
  const [participantJoined, setParticipantJoined] = useState<boolean>(false);
  const [isAddSignature, setIsAddSignature] = useState<boolean>(false);

  const [userIdAcs, setUserIdAcs] = useState<string>("");
  const [userAccessToken, setUserAccessToken] = useState<string>("");
  const [acsToken, setAcsToken] = useState<string>("");
  const [ownerMessage, setOwnerMessage] = useState<string>("");
  const [showDocumentPath, setShowDocumentPath] = useState<string>("");

  const [statefulCallClient, setStatefulCallClient] = useState<any>(null);
  const [callAgent, setCallAgent] = useState<any>(null);
  const [call, setCall] = useState<any>(null);
  const [currentStay, setCurrentStay] = useState<any>(null);

  const [showDocumentId, setShowDocumentId] = useState<number | null>(null);

  const onceTrigger = useRef(false);
  const onceTrigger2 = useRef(false);
  const dbCurrentCall = useRef<any>(null);
  const waitTimer = useRef<any>(null);

  const displayName = "Virtual Front Desk";

  const handleCallQueueNext = () => {
    localStorage.setItem("isOnPurpose", "true");

    if (!!dbCurrentCall.current) {
      update(dbCurrentCall.current, {
        status: 3,
      });
    }

    localStorage.setItem("currentCall", JSON.stringify(currentCall));
    localStorage.setItem("stationData", JSON.stringify(stationData));
    localStorage.setItem("onlinePeople", JSON.stringify(onlinePeople));
    localStorage.setItem(
      "currentCallQueuePosition",
      (
        parseInt(localStorage.getItem("currentCallQueuePosition") ?? "0") + 1
      ).toString()
    );

    localStorage.removeItem("participantJoined");

    clearTimeout(waitTimer.current);

    window.location.reload();
  };

  const handleScreenshot = async (currentStayProp: any, dbCall: any) => {
    const video: any = document.getElementsByTagName("video")[0];

    if (!video) return;

    const canvas = createCanvas(video.videoWidth, video.videoHeight);
    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

    const snapshot = canvas.toDataURL("image/png");
    var splitSnapshot = snapshot.split(",", 2);
    let data = {
      imageData: splitSnapshot[1],
      imageType: "png",
      ownerId: stationData.OwnerId,
    };

    const addedScreenshot = await addStayScreenshot(currentStayProp.id, data);

    if (addedScreenshot.status === 1) {
      update(dbCall, {
        takeScreenshot: false,
      });
    }
  };

  const closeCall = useCallback(() => {
    localStorage.removeItem("currentCall");
    localStorage.removeItem("stationData");
    localStorage.removeItem("currentCallQueuePosition");
    localStorage.removeItem("callBackLink");
    localStorage.removeItem("onlinePeople");

    navigate("/evaluation");
  }, [navigate]);

  const handleTakeId = async (
    data: number,
    currentStayProp: any,
    dbCall: any
  ) => {
    if (data === 1) {
      if (!idShareAccepted) {
        setShowAlertIdSharing(true);
      } else {
        setShowIdOverlay(true);
      }
    } else if (data === 2) {
      setShowIdOverlay(false);
      try {
        let video: any;
        if (!document.getElementsByTagName("video")[1]) {
          video = document.getElementsByTagName("video")[0];
        } else {
          video = document.getElementsByTagName("video")[1];
        }

        const canvas = createCanvas(video.videoWidth, video.videoHeight);

        canvas
          .getContext("2d")
          .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        const snapshot = canvas.toDataURL("image/png");
        const splitSnapshot = snapshot.split(",", 2);
        let data = {
          imageData: splitSnapshot[1],
          imageType: "png",
          ownerId: stationData.OwnerId,
        };

        const updatedStay = { ...currentStayProp };
        updatedStay["idCard"] = data;
        const edittedStay = await editStay(updatedStay);

        if (edittedStay.stay) {
          setCurrentStay(edittedStay);

          update(dbCall, {
            takeId: 3,
          });
        } else {
          alert(edittedStay.message);
        }
      } catch {
        console.log("Error takeId.");
      }
    }
  };

  const handleTermSubmit = async (
    data: any,
    imgData: string,
    hasSignature: boolean
  ) => {
    if (!hasSignature) {
      update(dbCurrentCall.current, {
        showTermsAndConditions: false,
      });

      setShowTermAndConditions(false);

      return;
    }

    const splitData = imgData.split(",", 2);

    const newStayData =
      currentStay && currentStay.stay
        ? {
            ...currentStay.stay,
          }
        : {
            ...currentStay,
          };

    newStayData.signature = {
      imageData: splitData[1],
      imageType: "png",
      documentId: showDocumentId,
    };

    newStayData.guestName = data.name;
    newStayData.guestEmail = data.email;

    const edittedStay = await editStay(newStayData);

    if (edittedStay.stay) {
      update(dbCurrentCall.current, {
        showTermsAndConditions: false,
      });

      setCurrentStay(edittedStay);
      setShowTermAndConditions(false);
    } else {
      alert(edittedStay.message);
    }
  };

  const handleHangOut = useCallback(
    (isCloseManually: boolean) => {
      if (!!dbCurrentCall.current) {
        update(dbCurrentCall.current, {
          status: 3,
        });

        off(dbCurrentCall.current, "child_changed", closeCall);

        if (isCloseManually) {
          closeCall();
        }
      } else {
        closeCall();
      }
    },
    [closeCall]
  );

  const handleAcsMeeting = async (callType: string, currentStayProp: any) => {
    const isMicrosoftCall =
      currentCall &&
      currentCall.microsoftCallerIds &&
      currentCall.microsoftCallerIds.length > 0;

    const isDepartmentCallQueue =
      currentCall &&
      currentCall.regularUserIds &&
      currentCall.regularUserIds.length > 0 &&
      currentDepartmentData &&
      currentDepartmentData.CallMethod === 1;

    const acsAuth = await getAcsAuth(
      isMicrosoftCall ? stationData.StationCode : "DashboardData"
    );
    setUserIdAcs(acsAuth.userId);
    setUserAccessToken(acsAuth.token);

    if (isMicrosoftCall) {
      return;
    }

    let localStorageToken = localStorage.getItem("callBackLink");
    if (!localStorageToken) {
      const serverAcsToken = await getAcsToken();
      setAcsToken(serverAcsToken.data);
      localStorageToken = serverAcsToken.data;
    } else {
      setAcsToken(localStorageToken);
    }

    if (localStorage.getItem("callBackLink")) {
      localStorage.setItem("participantJoined", "true");
      setParticipantJoined(true);
    }

    let currentCallQueuePosition = parseInt(
      localStorage.getItem("currentCallQueuePosition") ?? "0"
    );

    for (
      let i = currentCallQueuePosition;
      i < currentCall.regularUserIds.length;
      i++
    ) {
      if (
        onlinePeople.some(
          (userid: any) => userid === currentCall.regularUserIds[i]
        )
      ) {
        currentCallQueuePosition = i;
        localStorage.setItem(
          "currentCallQueuePosition",
          currentCallQueuePosition.toString()
        );
        break;
      }
    }

    let callObject = {
      stationName: stationData.StationName,
      dwellingName: stationData.DwellingName,
      callType: callType ?? 1,
      startTime: new Date().getTime(),
      onHold: false,
      timerPause: false,
      dwellingId: stationData.DwellingId.toString(),
      message: "",
      receptionist: "",
      status: 1,
      acsLink: localStorageToken,
      stayId: 0,
      takeId: 0,
      showTermsAndConditions: false,
      takeScreenshot: false,
      callTypeName: currentCall.callTypeName ?? "",
      stationCode: stationData.StationCode ?? "",
      selectedDepartments: currentCall.selectedDepartments ?? "",
      selectedEmployees: isDepartmentCallQueue
        ? `${currentCall.regularUserIds[currentCallQueuePosition]}`
        : currentCall.selectedEmployees ?? "",
      isPrintDocument: false,
      printerEndpoint: "",
      showDocumentPath: "",
    };

    if (!currentStayProp) {
      callObject.stayId = currentCall.stayId;
    } else {
      callObject.stayId = currentStayProp.id;
    }

    const queryCall = ref(
      db,
      `calls/${stationData.Auth0Id ? stationData.Auth0Id : stationData.OwnerId}`
    );
    const createdCall =
      currentCall.currentCall || push(queryCall, callObject).key;
    const dbCall = ref(
      db,
      `calls/${
        stationData.Auth0Id ? stationData.Auth0Id : stationData.OwnerId
      }/${createdCall}`
    );

    dbCurrentCall.current = dbCall;

    onChildChanged(dbCall, async (data) => {
      if (data.key === "showTermsAndConditions") {
        onValue(dbCall, async (data) => {
          setShowDocumentPath(data.val().showDocumentPath);
          setShowTermAndConditions(data.val().showTermsAndConditions);
          setIsAddSignature(data.val().hasSignatureAndName);
          if (data.val().hasSignatureAndName) {
            setShowDocumentId(data.val().showDocumentId);
          }
        });
      }
      if (data.key === "takeId") {
        if (data.val() === 1) {
          await handleTakeId(1, currentStayProp, dbCall);
        } else if (data.val() === 2) {
          await handleTakeId(2, currentStayProp, dbCall);
        }
      }
      if (data.key === "takeScreenshot") {
        if (data.val()) {
          await handleScreenshot(currentStayProp, dbCall);
        }
      }
      if (data.key === "onHold") {
        if (data.val()) {
          setOnHold(true);
        } else {
          setOnHold(false);
        }
      }
      if (data.key === "status") {
        if (data.val() === 3) {
          const isOnPurpose = localStorage.getItem("isOnPurpose") === "true";

          if (isOnPurpose) {
            localStorage.removeItem("isOnPurpose");
            return;
          }

          const participantJoined = localStorage.getItem("participantJoined");

          if (
            currentCall.regularUserIds.length >
              parseInt(
                localStorage.getItem("currentCallQueuePosition") ?? "0"
              ) +
                1 &&
            participantJoined !== "true" &&
            currentDepartmentData &&
            currentDepartmentData.CallMethod === 1
          ) {
            let isHangOut = true;
            for (
              let i =
                parseInt(
                  localStorage.getItem("currentCallQueuePosition") ?? "0"
                ) + 1;
              i < currentCall.regularUserIds.length;
              i++
            ) {
              if (
                onlinePeople.some(
                  (userid: any) => userid === currentCall.regularUserIds[i]
                )
              ) {
                isHangOut = false;

                handleCallQueueNext();

                break;
              }
            }

            if (isHangOut) {
              handleHangOut(true);
            }
          } else {
            handleHangOut(true);
          }
        }
      }
      if (data.key === "message") {
        setOwnerMessage(data.val());
      }
      if (data.key === "timerPause") {
        if (!!waitTimer) clearTimeout(waitTimer.current);
      }
    });
  };

  const handleStationCall = async () => {
    let callType = currentCall.callType;
    if (callType === null) {
      callType = 1;
    }

    if (!!currentCall.stayId) {
      setCurrentStay({
        id: currentCall.stayId,
        ownerId: stationData.OwnerId,
      });
      localStorage.setItem("stayId", currentCall.stayId);
      await handleAcsMeeting(callType, {
        id: currentCall.stayId,
        ownerId: stationData.OwnerId,
      });
    } else {
      const addStayData = {
        ownerId: stationData.OwnerId,
        language: !!i18n.languages[0] ? i18n.languages[0] : "fr",
        callType: callType,
        callTypeName: currentCall.callTypeName,
        stationName: stationData.StationName,
      };

      const serverResponse = await addStay(stationData.DwellingId, addStayData);

      if (serverResponse.stay) {
        let currentStayData = { ...serverResponse.stay };
        currentStayData.ownerId = stationData.OwnerId;
        setCurrentStay(currentStayData);
        localStorage.setItem("stayId", currentStayData.id);
        await handleAcsMeeting(callType, currentStayData);
      } else {
        alert(serverResponse.message);
        navigate("/");
      }
    }
  };

  useEffect(() => {
    if (participantJoined) document.body.style.backgroundColor = "#202124";

    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [participantJoined]);

  useEffect(() => {
    if (onceTrigger2.current === false) {
      onceTrigger2.current = true;

      const asyncFunction = async () => {
        await handleStationCall();
      };

      if (!!currentCall) {
        asyncFunction();

        localStorage.setItem("participantJoined", "false");
        waitTimer.current = setTimeout(
          () => {
            const participantJoined = localStorage.getItem("participantJoined");

            if (participantJoined !== "true") {
              if (
                currentDepartmentData &&
                currentDepartmentData.CallMethod === 1 &&
                (currentCall.microsoftCallerIds.length > 0 ||
                  currentCall.regularUserIds.length > 0) &&
                (parseInt(
                  localStorage.getItem("currentCallQueuePosition") ?? "0"
                ) <
                  currentCall.microsoftCallerIds.length - 1 ||
                  parseInt(
                    localStorage.getItem("currentCallQueuePosition") ?? "0"
                  ) <
                    currentCall.regularUserIds.length - 1)
              ) {
                handleCallQueueNext();
              } else {
                if (!!currentCall.fallBackCall) {
                  const callData: any = {
                    fallBackCall: currentCall.fallBackCall,
                  };

                  handleHangOut(false);
                  dispatch(setCallData(callData));

                  localStorage.setItem(
                    "fallbacknumber",
                    currentCall.fallBackCall
                  );

                  localStorage.removeItem("currentCall");
                  localStorage.removeItem("stationData");
                  localStorage.removeItem("currentCallQueuePosition");

                  navigate("/call-fallback");
                  window.location.reload();
                } else {
                  handleHangOut(true);
                }
              }
            } else {
              clearTimeout(waitTimer.current);
            }
          },
          !!currentCall.fallBackCall
            ? currentCall.callForwardTime
              ? currentCall.callForwardTime * 1000
              : 30000
            : currentCall.microsoftCallerIds &&
                currentCall.microsoftCallerIds.length > 0
              ? 58000
              : 110000
        );
      } else {
        localStorage.removeItem("currentCall");
        localStorage.removeItem("stationData");
        localStorage.removeItem("currentCallQueuePosition");

        navigate("/");
        window.location.reload();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tokenCredential = useMemo(() => {
    if (!userAccessToken) return null;

    return new AzureCommunicationTokenCredential(userAccessToken);
  }, [userAccessToken]);

  useEffect(() => {
    initializeIcons();
    registerIcons({ icons: DEFAULT_COMPONENT_ICONS });
  }, []);

  useEffect(() => {
    if (!userIdAcs) return;
    setStatefulCallClient(
      createStatefulCallClient({
        userId: { communicationUserId: userIdAcs },
      })
    );
  }, [userIdAcs]);

  useEffect(() => {
    if (!callAgent && statefulCallClient && onceTrigger.current === false) {
      onceTrigger.current = true;
      const createUserAgent = async () => {
        setCallAgent(
          await statefulCallClient.createCallAgent(tokenCredential, {
            displayName: displayName,
          })
        );
      };
      createUserAgent();
    }
  }, [callAgent, statefulCallClient, tokenCredential]);

  useEffect(() => {
    const getCamera = async () => {
      if (!statefulCallClient) return;
      if (!callAgent) return;
      if (
        !acsToken &&
        !(
          currentCall &&
          currentCall.microsoftCallerIds &&
          currentCall.microsoftCallerIds.length > 0
        )
      )
        return;

      const deviceManager = await statefulCallClient.getDeviceManager();
      const permission = await deviceManager.askDevicePermission({
        audio: true,
        video: true,
      });
      if (!permission) {
        alert("Please allow camera and microphone access to use this feature.");
      }

      const cameras = await deviceManager.getCameras();
      const camera = cameras[0];
      const localVideoStream2 = new LocalVideoStream(camera);

      let callObj;

      if (
        currentCall &&
        currentCall.microsoftCallerIds &&
        currentCall.microsoftCallerIds.length > 0
      ) {
        let containsCallQueue = false;

        if (currentCall && currentCall.microsoftCallerIds) {
          currentCall.microsoftCallerIds.forEach(
            (userCaller: { id: string; isCallQueue: boolean }) => {
              if (userCaller.isCallQueue) {
                containsCallQueue = true;
              }
            }
          );
        }

        if (containsCallQueue) {
          callObj = callAgent.startCall(
            currentCall.microsoftCallerIds.map(
              (userCaller: { id: string; isCallQueue: boolean }) => {
                return {
                  teamsAppId: userCaller.id.trim(),
                  cloud: "public",
                };
              }
            ),
            {
              videoOptions: localVideoStream2
                ? {
                    localVideoStreams: [localVideoStream2],
                    /*constraints: {
                    send: {
                      bitrate: {
                        max: 575000,
                      },
                      frameHeight: {
                        max: 240,
                      },
                      frameRate: {
                        max: 25,
                      },
                    },
                  },*/
                  }
                : undefined,
            }
          );
        } else {
          callObj = callAgent.startCall(
            currentDepartmentData && currentDepartmentData.CallMethod === 1
              ? [
                  {
                    microsoftTeamsUserId:
                      currentCall.microsoftCallerIds[
                        parseInt(
                          localStorage.getItem("currentCallQueuePosition") ??
                            "0"
                        )
                      ].id,
                  },
                ]
              : currentCall.microsoftCallerIds.map(
                  (userCaller: { id: string; isCallQueue: boolean }) => {
                    return {
                      microsoftTeamsUserId: userCaller.id,
                    };
                  }
                ),
            {
              videoOptions: localVideoStream2
                ? {
                    localVideoStreams: [localVideoStream2],
                    /*constraints: {
                    send: {
                      bitrate: {
                        max: 575000,
                      },
                      frameHeight: {
                        max: 240,
                      },
                      frameRate: {
                        max: 25,
                      },
                    },
                  },*/
                  }
                : undefined,
            }
          );
        }
      } else {
        callObj = callAgent.join(
          {
            meetingLink: acsToken,
          },
          {
            videoOptions: localVideoStream2
              ? {
                  localVideoStreams: [localVideoStream2],
                  /*constraints: {
                    send: {
                      bitrate: {
                        max: 575000,
                      },
                      frameHeight: {
                        max: 240,
                      },
                      frameRate: {
                        max: 25,
                      },
                    },
                  },*/
                }
              : undefined,
          }
        );
      }

      setCall(callObj);
    };
    getCamera();
  }, [statefulCallClient, callAgent, acsToken]);

  useEffect(() => {
    if (call) {
      call.on("stateChanged", () => {
        if (
          currentCall &&
          currentCall.microsoftCallerIds &&
          currentCall.microsoftCallerIds.length > 0 &&
          call.state === "Connected"
        ) {
          localStorage.setItem("participantJoined", "true");
          setParticipantJoined(true);
        }

        if (call.state === "Disconnected") {
          const isJoined = localStorage.getItem("participantJoined") === "true";

          if (
            currentDepartmentData &&
            currentDepartmentData.CallMethod === 1 &&
            parseInt(localStorage.getItem("currentCallQueuePosition") ?? "0") <
              currentCall.microsoftCallerIds.length - 1 &&
            currentCall.microsoftCallerIds.length > 0 &&
            !isJoined
          ) {
            handleCallQueueNext();
          } else {
            handleHangOut(true);
          }
        }
      });

      if (
        currentCall &&
        currentCall.microsoftCallerIds &&
        currentCall.microsoftCallerIds.length > 0
      ) {
        call.on("totalParticipantCountChanged", () => {
          if (call.state === "Connected") {
            if (call.remoteParticipants.length === 1) {
              let hasRemoteDisplayName = false;
              call.remoteParticipants.forEach((participant: any) => {
                for (const prop in participant) {
                  if (prop === "_displayName" && participant[prop] !== "") {
                    hasRemoteDisplayName = true;
                  }
                }
              });

              if (hasRemoteDisplayName) {
                localStorage.setItem("participantJoined", "true");
                editStay({
                  id:
                    currentStay && currentStay.data
                      ? currentStay.data.id
                      : currentStay.id,
                  receptionist: "Microsoft Teams Call",
                });
                setParticipantJoined(true);
              } else {
                const isJoined =
                  localStorage.getItem("participantJoined") === "true";
                if (
                  currentDepartmentData &&
                  currentDepartmentData.CallMethod === 1 &&
                  parseInt(
                    localStorage.getItem("currentCallQueuePosition") ?? "0"
                  ) <
                    currentCall.microsoftCallerIds.length - 1 &&
                  currentCall.microsoftCallerIds.length > 0 &&
                  !isJoined
                ) {
                  handleCallQueueNext();
                } else {
                  handleHangOut(true);
                }
              }
            } else {
              localStorage.setItem("participantJoined", "true");
              editStay({
                id:
                  currentStay && currentStay.data
                    ? currentStay.data.id
                    : currentStay.id,
                receptionist: "Microsoft Teams Call",
              });
              setParticipantJoined(true);
            }
          }

          if (call.state === "Disconnected") {
            const isJoined =
              localStorage.getItem("participantJoined") === "true";

            if (
              currentDepartmentData &&
              currentDepartmentData.CallMethod === 1 &&
              parseInt(
                localStorage.getItem("currentCallQueuePosition") ?? "0"
              ) <
                currentCall.microsoftCallerIds.length - 1 &&
              currentCall.microsoftCallerIds.length > 0 &&
              !isJoined
            ) {
              handleCallQueueNext();
            } else {
              handleHangOut(true);
            }
          }
        });
      } else {
        call.on("remoteParticipantsUpdated", () => {
          localStorage.setItem("participantJoined", "true");
          setParticipantJoined(true);
        });
      }
    }
  }, [call, currentCall, currentStay, handleHangOut]);

  const handleIdShare = () => {
    setShowAlertIdSharing(false);
    setIdShareAccepted(true);
    setShowIdOverlay(true);
  };

  return (
    <>
      <Transition appear show={showAlertIdSharing} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[1000001]"
          onClose={() => {
            setShowAlertIdSharing(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-bold leading-6 text-gray-900 text-center"
                  >
                    {t("termsTitle")}
                  </Dialog.Title>
                  <div className="mt-6 w-full flex justify-center">
                    <p className="text-light-gray text-xl">
                      {t("termsIdAuth")}
                    </p>
                  </div>

                  <div className="mt-6 w-full flex justify-center">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-lg border border-transparent bg-primary px-10 py-3 text-lg font-bold text-white focus:outline-none"
                      onClick={() => {
                        handleIdShare();
                      }}
                    >
                      {t("accept")}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {showTermAndConditions && showDocumentPath ? (
        <Terms
          documentPath={showDocumentPath}
          guestName={!!currentStay.guestName ? currentStay.guestName : ""}
          guestEmail={!!currentStay.guestEmail ? currentStay.guestEmail : ""}
          isAddSignature={isAddSignature}
          handleSignSubmit={async (
            data: any,
            imgData: string,
            hasSignature: boolean
          ) => await handleTermSubmit(data, imgData, hasSignature)}
        />
      ) : null}

      {!participantJoined ? (
        <div className="w-[100vw] h-[100vh] flex flex-col items-center justify-center">
          <div className="w-full md:w-3/4 flex flex-col items-center">
            <div className="w-full flex justify-center">
              <svg
                className="animate-spin -ml-1 mr-3 h-20 w-20 text-primary"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                />
              </svg>
            </div>
            <p className="text-light-gray text-3xl mt-5">{t("callLoading")}</p>
            <p className="text-light-gray text-2xl">
              {t("callLoadingMessage")}
            </p>
            <div className="w-full flex justify-center items-center mt-10">
              <button
                className="inline-flex justify-center rounded-full border border-transparent bg-red px-16 py-4 text-lg font-medium text-white hover:bg-redLight focus:outline-none"
                onClick={() => handleHangOut(true)}
              >
                {t("endCall")}
              </button>
            </div>
          </div>
        </div>
      ) : null}

      <Transition appear show={ownerMessage !== ""} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[1000001]"
          onClose={() => {
            setOwnerMessage("");
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="mt-6 w-full flex justify-center">
                    {ownerMessage.includes("http") ? (
                      <QRCode value={ownerMessage} />
                    ) : (
                      <p className="text-light-gray text-3xl text-center">
                        {ownerMessage}
                      </p>
                    )}
                  </div>

                  <div className="mt-6 w-full flex justify-center">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-lg border border-transparent bg-primary px-10 py-3 text-lg font-bold text-white focus:outline-none"
                      onClick={() => {
                        setOwnerMessage("");
                      }}
                    >
                      {t("close")}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={onHold} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[1000001]"
          onClose={() => {
            setOnHold(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all py-20 px-10">
                  <Dialog.Title
                    as="h3"
                    className="text-3xl font-bold leading-6 text-gray-900 text-center"
                  >
                    {t("wait")}
                  </Dialog.Title>
                  <div className="mt-6 w-full flex justify-center">
                    <p className="text-light-gray text-xl">
                      {t("waitMessage")}
                    </p>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {(acsToken ||
        (currentCall &&
          currentCall.microsoftCallerIds &&
          currentCall.microsoftCallerIds.length > 0)) &&
        userAccessToken &&
        userIdAcs && (
          <div
            className={`w-[100vw] h-[100vh] flex ${
              participantJoined
                ? "flex flex-col justify-center items-center"
                : "hidden"
            }`}
          >
            <FluentThemeProvider>
              {statefulCallClient && (
                <CallClientProvider callClient={statefulCallClient}>
                  {callAgent && (
                    <CallAgentProvider callAgent={callAgent}>
                      {call && (
                        <CallProvider call={call}>
                          <StationVideo
                            handleHangOut={() => handleHangOut(true)}
                            showIdOverlay={showIdOverlay}
                            call={call}
                          />
                        </CallProvider>
                      )}
                    </CallAgentProvider>
                  )}
                </CallClientProvider>
              )}
            </FluentThemeProvider>
          </div>
        )}
    </>
  );
}
